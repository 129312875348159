export enum DemandeSignatureErrorType {
  DEMANDE_NOT_UNILATERALE = "DEMANDE_NOT_UNILATERALE",
  REQUIRED_BANQUE_NAME = "REQUIRED_BANQUE_NAME",
  FETCH_GROUP_TREE_ERROR = "FETCH_GROUP_TREE_ERROR",
  REQUIRED_BANQUE = "REQUIRED_BANQUE",
  INTERNAL_DOCUSIGN_ERROR = "INTERNAL_DOCUSIGN_ERROR",
  ORDERED_SIGNATURE_WITHOUT_FIRST_SIGNER = "ORDERED_SIGNATURE_WITHOUT_FIRST_SIGNER",
  BAD_DOCUMENT_SIZE = "BAD_DOCUMENT_SIZE",
  BAD_DOCUMENT_FORMAT = "BAD_DOCUMENT_FORMAT",
  BAD_CONFIGURATION = "BAD_CONFIGURATION",
}
