import { DocusignConfigurationError } from "@domain/enum/docusignConfigurationError";
import { PoolAccordCommercialSignatureErrorType } from "@domain/dto/errors/poolAccordCommercialSignatureErrorType";
import { isSomeEnum } from "@domain/utils/enumUtils";

export interface PoolAccordCommercialSignatureConfigurationError {
  type: PoolAccordCommercialSignatureErrorType.BAD_CONFIGURATION;
  docusignConfigurationErrorList: DocusignConfigurationError[];
}

export type PoolAccordCommercialSignatureCommonErrorType = Exclude<
  PoolAccordCommercialSignatureErrorType,
  PoolAccordCommercialSignatureErrorType.BAD_CONFIGURATION
>;
export interface PoolAccordCommercialSignatureCommonError {
  type: PoolAccordCommercialSignatureCommonErrorType;
}

export type PoolAccordCommercialSignatureError =
  | PoolAccordCommercialSignatureConfigurationError
  | PoolAccordCommercialSignatureCommonError;

export function isPoolAccordCommercialSignatureCommonErrorType(
  value: unknown
): value is PoolAccordCommercialSignatureCommonErrorType {
  return (
    isSomeEnum(PoolAccordCommercialSignatureErrorType)(value) &&
    value !== PoolAccordCommercialSignatureErrorType.BAD_CONFIGURATION
  );
}
