import {
  OperationLight,
  TiersOperationLight,
} from "@/domain/dto/operationLight";
import { OperationSorting } from "@/domain/operationSorting";
import { OperationHeavy, TiersOperationHeavy } from "@/domain/operationHeavy";
import { formatRegionName } from "@/utils/formatNameUtils";
import { OperationSortingType } from "@domain/enum/operationSortingType";
import { SortingOrder } from "@domain/enum/sortingOrder";
import { OperationCreationRequest } from "@domain/dto/operationCreationRequest";
import { DemandeType } from "@domain/enum/demandeType";
import uniq from "lodash/uniq";
import { DemandeStatus } from "@domain/enum/demandeStatus";
import { Role } from "@domain/enum/role";
import { SocieteSupport } from "@/domain/societeSupport";
import { Demande } from "@/domain/demande";
import { Group } from "@domain/dto/group";
import { PoolOperationHeavy } from "@/domain/dto/poolOperationHeavy";
import { PoolDemandeHeavy } from "@/domain/dto/poolDemandeHeavy";
import { NotificationPayload } from "@/domain/notificationPayload";
import { OperationMessageCanal } from "@domain/enum/operationMessageCanal";
import { User } from "@domain/dto/user";
import { OperationChatFilter } from "@/domain/operationChatFilter";
import { getMasterGroupId } from "./groupUtils";
import { isDemandeSigned } from "@/utils/demandeUtils";
import { OperationType } from "@domain/enum/operationType";
import { SocieteSupportType } from "@domain/enum/societeSupportType";
import { DemandeLight } from "@/domain/dto/demandeLight";
import { OperationTeamType } from "@/domain/enum/operationTeamType";
import { AccessType } from "@/domain/enum/accessType";
import { OperationAccessAsRole } from "@/domain/enum/operationAccessAsRole";
import {
  DemandeArchive,
  OperationArchiveHeavy,
} from "@/domain/operationArchivedHeavy";
import { ESGFormStatus } from "@domain/enum/ESGFormStatus";
import { sortListByUpdatedAt } from "@/utils/dateUtils";
import { PoolAccessMode } from "@domain/enum/poolAccessMode";

export function createOperationHeavy(
  operation: Partial<OperationHeavy> = {}
): OperationHeavy {
  return {
    id: "",
    adresseProgramme: "",
    codePostal: "",
    idSocieteSupportOrigine: "",
    societeSupportOrigine: {
      id: "",
      idPromoteur: "",
      creationInProgress: false,
      name: "",
      siren: "",
      type: SocieteSupportType.FILIALE,
      accessType: AccessType.NORMAL,
      promoteur: {
        id: "",
        name: "",
        roles: [],
        users: [],
        monoSignaturePromoteur: [],
        doubleSignaturePromoteur: [],
        promoteursSignatairesGfa: [],
        reportingColor: "",
        logoColor: "",
        idLogoGroup: "",
      },
    },
    referencePromoteur: "",
    referenceBanque: "",
    referents: {},
    euroCentimeChiffreAffaire: 0,
    name: "",
    region: "",
    ville: "",
    description: "",
    step: 1,
    groups: [],
    isPoolMode: false,
    idPromoteurOwner: "",
    demandeList: [],
    isChefDeFile: false,
    idBanqueList: [],
    type: OperationType.RESIDENTIELLE,
    referentBanqueList: [],
    idPoolFormalise: null,
    operationAccessAsRole: OperationAccessAsRole.NONE,
    esgFormStatus: ESGFormStatus.NOT_INITIALIZED,
    idBanqueListRequestingEsgForm: [],
    ...operation,
  };
}
export function createTiersOperationHeavy(): TiersOperationHeavy {
  return {
    id: "",
    adresseProgramme: "",
    codePostal: "",
    idSocieteSupportOrigine: "",
    societeSupportOrigine: {
      id: "",
      idPromoteur: "",
      creationInProgress: false,
      name: "",
      siren: "",
      type: SocieteSupportType.FILIALE,
      accessType: AccessType.NORMAL,
      promoteur: {
        id: "",
        name: "",
        roles: [],
        users: [],
        monoSignaturePromoteur: [],
        doubleSignaturePromoteur: [],
        promoteursSignatairesGfa: [],
        reportingColor: "",
        logoColor: "",
        idLogoGroup: "",
      },
    },
    referencePromoteur: "",
    referenceBanque: "",
    euroCentimeChiffreAffaire: 0,
    name: "",
    region: "",
    ville: "",
    description: "",
    step: 1,
    groups: [],
    idPromoteurOwner: "",
    demandeList: [],
    idBanqueList: [],
    type: OperationType.RESIDENTIELLE,
    idPoolFormalise: null,
    operationAccessAsRole: OperationAccessAsRole.NONE,
    esgFormStatus: ESGFormStatus.NOT_INITIALIZED,
    idBanqueListRequestingEsgForm: [],
  };
}

export function createOperationSorting(): OperationSorting {
  return {
    type: OperationSortingType.CREATION_DATE,
    order: SortingOrder.DESCENDING,
  };
}

export function capitalizeOperationName(
  operation: OperationCreationRequest
): OperationCreationRequest {
  return {
    ...operation,
    name: operation.name.charAt(0).toUpperCase() + operation.name.slice(1),
  };
}

export function formatRegionList(regionList: string[]): string[] {
  const formattedRegionList = Array.from(
    new Set(regionList.map(formatRegionName))
  );
  return formattedRegionList.sort((a, b) => a.localeCompare(b));
}

export function demandeTypesInOperations(
  operations: OperationLight[]
): DemandeType[] {
  const demandes = operations
    .flatMap((operation) => operation.demandeList)
    .filter((demande) => demande !== undefined);
  return uniq(demandes.map((demande) => demande.type));
}

export function demandeStatusInOperations(
  operations: OperationLight[]
): DemandeStatus[] {
  const demandes = operations
    .flatMap((operation) => operation.demandeList)
    .filter((demande) => demande !== undefined);
  return uniq(demandes.map((demande) => demande.status));
}

// NEW
export function getSocieteSupportListByOperation(
  operation: OperationHeavy | PoolOperationHeavy | OperationArchiveHeavy
): SocieteSupport[] {
  const idList = new Set();
  const societeList: SocieteSupport[] = [];

  operation.demandeList.forEach((demande: Demande | PoolDemandeHeavy) => {
    const societeSupport = demande.societeSupport;
    if (!idList.has(societeSupport.id)) {
      idList.add(societeSupport.id);
      societeList.push(societeSupport);
    }
  });

  return societeList;
}

export function getUnsharedDemandeListByIdSocieteSupport(
  operation: {
    demandeList: Demande[] | DemandeArchive[];
  },
  idSocieteSupport: string
): Demande[] {
  const demandeList = operation.demandeList.filter(
    (demande) => demande.societeSupport.id === idSocieteSupport
  );

  return demandeList.filter(
    (demande) => !demande.idBanque && !demande.demandeUnilateralBanqueName
  );
}

export function getSharedDemandeListByIdSocieteSupport(
  operation: {
    demandeList: Demande[] | DemandeArchive[];
    groups: Group[];
  },
  idSocieteSupport: string
): Demande[][] {
  const banqueList = getBanqueListByIdSocieteSupport(
    operation,
    idSocieteSupport
  );

  return banqueList.map((banque) => {
    if (banque.idBanque) {
      return getSortedOperationDemandeListByIdBanqueAndIdSocieteSupport(
        operation,
        banque.idBanque,
        idSocieteSupport
      );
    }
    if (banque.demandeUnilateralBanqueName) {
      return getSortedOperationDemandeListByBanqueUnilateraleAndIdSocieteSupport(
        operation,
        banque.demandeUnilateralBanqueName,
        idSocieteSupport
      );
    }
    console.error(
      "banque does not have idBanque or demandeUnilateralBanqueName"
    );
    return [];
  });
}

export function getBanqueListByIdSocieteSupport(
  operation: {
    demandeList: Demande[] | DemandeArchive[];
    groups: Group[];
  },
  idSocieteSupport: string
): { idBanque?: string; demandeUnilateralBanqueName?: string }[] {
  const demandeList = operation.demandeList.filter(
    (demande) => demande.societeSupport.id === idSocieteSupport
  );

  const idBanqueListFull = demandeList.map((demande) => demande.idBanque);
  const uniqueIdBanqueList = Array.from(new Set(idBanqueListFull));

  const banqueList = uniqueIdBanqueList.map((idBanque) =>
    getOperationBanqueByIdBanque(operation, idBanque)
  );

  const unilateraleBanqueList = demandeList
    .map((demande) => demande.demandeUnilateralBanqueName)
    .filter(
      (banqueName): banqueName is string => typeof banqueName === "string"
    );
  const uniqueUnilateraleBanqueNameList = [
    ...new Set(unilateraleBanqueList),
  ].map((banqueName) => ({
    demandeUnilateralBanqueName: banqueName,
  }));

  const idBanqueList = banqueList
    .filter(
      (banque): banque is Group & { name: string } =>
        !!banque && banque.name !== undefined
    )
    .map((banque) => ({
      idBanque: banque.id,
    }));

  return [...idBanqueList, ...uniqueUnilateraleBanqueNameList];
}

export function getSortedOperationDemandeListByBanqueUnilateraleAndIdSocieteSupport(
  operation: {
    demandeList: Demande[] | DemandeArchive[];
    groups: Group[];
  },
  demandeUnilateraleBanqueName: string,
  idSocieteSupport: string
): Demande[] {
  return operation.demandeList
    .filter(
      (demande) =>
        demande.demandeUnilateralBanqueName === demandeUnilateraleBanqueName &&
        demande.societeSupport.id === idSocieteSupport
    )
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
}

export function getSortedOperationDemandeListByIdBanqueAndIdSocieteSupport(
  operation: {
    demandeList: Demande[] | DemandeArchive[];
    groups: Group[];
  },
  idBanque: string,
  idSocieteSupport: string
): Demande[] {
  return operation.demandeList
    .filter(
      (demande) =>
        demande.idBanque === idBanque &&
        demande.societeSupport.id === idSocieteSupport
    )
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
}
export function getOperationBanqueByIdBanque(
  operation: { groups: Group[] },
  idBanque?: string
): Group | undefined {
  return operation.groups.find((group) => group.id === idBanque);
}

export function getSortedOperationDemandeListToMostRecent(
  operation: OperationHeavy | OperationArchiveHeavy
): Demande[] | undefined {
  return operation.demandeList
    .slice()
    .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
}

export function getLastUpdatedDemandeByOperationLight(
  operation: OperationLight | TiersOperationLight
): DemandeLight | undefined {
  const demandeList = operation.demandeList
    .slice()
    .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
  if (demandeList && demandeList.length > 0) return demandeList[0];
}

export function getLastUpdatedDemandeWithActiveBanqueByOperation(
  operation: OperationHeavy
): Demande | undefined {
  const demandeList = getSortedOperationDemandeListToMostRecent(operation);
  if (demandeList) return demandeList.find((demande) => !!demande.idBanque);
}

export function isSignedDemandeInOperation(operation: OperationHeavy): boolean {
  return operation.demandeList.some((demande) => isDemandeSigned(demande.step));
}

export function computeChatFilter(
  payload: NotificationPayload,
  operation: OperationHeavy,
  user: User
): OperationChatFilter {
  const isMentionNotification = !!payload.messageMentionNotificationContent;

  if (isMentionNotification) {
    const { messageMentionNotificationContent } = payload;
    return {
      canal: messageMentionNotificationContent!.canal,
      idBanque: messageMentionNotificationContent?.idBanque,
      idPromoteur: messageMentionNotificationContent?.idPromoteur,
      idPoolGroup: messageMentionNotificationContent?.idPoolGroup,
    };
  }

  const isGeneral = !!payload.messageExternalNotificationContent;

  if (isGeneral) {
    const idBanque = payload.messageExternalNotificationContent?.idBanque;
    return {
      canal: OperationMessageCanal.GENERAL,
      idBanque,
      idPromoteur: operation.idPromoteurOwner,
    };
  } else if (user.roles.includes(Role.BANQUE)) {
    return {
      canal: OperationMessageCanal.INTERNE,
      idBanque: getMasterGroupId(user),
    };
  } else {
    return {
      canal: OperationMessageCanal.INTERNE,
      idPromoteur: getMasterGroupId(user),
    };
  }
}

export function operationTypeLabel(operationType: OperationType): string {
  switch (operationType) {
    case OperationType.RESIDENTIELLE:
      return "Résid./Mixte";
    case OperationType.TERTIAIRE:
      return "Tertiaire";
    default:
      return "";
  }
}

export function getOperationTeamWording(teamType: OperationTeamType) {
  switch (teamType) {
    case OperationTeamType.EQUIPE:
      return {
        categoryTitle: "Équipe",
        userLabel: "membre",
      };

    case OperationTeamType.TIERS:
      return {
        categoryTitle: "Tiers",
        userLabel: "visiteur",
      };

    case OperationTeamType.PARTENAIRE:
      return {
        categoryTitle: "Partenaires",
        userLabel: "interlocuteur",
      };
  }
}

export function getLastUpdatedDemandeWithBanqueInOperation(
  operation: OperationHeavy | OperationArchiveHeavy
): Demande | undefined {
  const demandeList = sortListByUpdatedAt(operation.demandeList)?.filter(
    (demande) => demande.idBanque || demande.demandeUnilateralBanqueName
  );
  if (demandeList && demandeList.length > 0) {
    if (demandeList.find((d) => d.idBanque)) {
      return demandeList.filter((d) => !d.demandeUnilateralBanqueName)[0];
    }
    return demandeList[0];
  }
}

export function getBanquePartnerName(
  operation: OperationHeavy | OperationArchiveHeavy
): string {
  const lastUpdatedDemandeWithBanqueInOperation =
    getLastUpdatedDemandeWithBanqueInOperation(operation);

  if (
    operation &&
    operation.operationAccessAsRole === OperationAccessAsRole.PARTICIPANT
  ) {
    return getChefDeFileName(operation);
  }

  if (!lastUpdatedDemandeWithBanqueInOperation) {
    return "Non renseigné";
  }
  if (lastUpdatedDemandeWithBanqueInOperation.demandeUnilateralBanqueName) {
    return lastUpdatedDemandeWithBanqueInOperation.demandeUnilateralBanqueName;
  }

  const banque = getOperationBanqueByIdBanque(
    operation,
    lastUpdatedDemandeWithBanqueInOperation.idBanque
  );
  if (!banque) {
    return "Non renseigné";
  }
  return banque.name;
}

export function getPromoteurName(
  operation: OperationHeavy | OperationArchiveHeavy
): string {
  const promoteurGroup = operation.groups.find((group) =>
    group.roles.includes(Role.PROMOTEUR)
  );

  return promoteurGroup?.name ?? "";
}

export function isOperationConcernedByPool(operation: OperationHeavy): boolean {
  return (
    [
      OperationAccessAsRole.PARTICIPANT,
      OperationAccessAsRole.PARTICIPANT_AND_BANQUE,
    ].includes(operation.operationAccessAsRole) || operation.isChefDeFile
  );
}

export function getChefDeFileName(
  operation: OperationHeavy | OperationArchiveHeavy
): string {
  if (operation.groups.length > 0) {
    const banqueGroup = operation.groups.find((group: Group) =>
      group.roles.includes(Role.BANQUE)
    );
    return banqueGroup?.name ?? "";
  }
  return "";
}

export function createOperationLight(
  operation: Partial<OperationLight> = {}
): OperationLight {
  return {
    id: "",
    name: "",
    ville: "",
    region: "",
    demandeList: [],
    lettreAccordList: [],
    groups: [],
    createdAt: new Date(),
    joinDate: new Date(),
    referencePromoteur: "",
    referenceBanque: "",
    euroCentimeChiffreAffaire: 0,
    poolAccessMode: PoolAccessMode.UNSHARED,
    type: OperationType.RESIDENTIELLE,
    ...operation,
  };
}
export function isOperationSharedToAtLeastOneRealBanque(
  operation: OperationHeavy
): boolean {
  return operation.demandeList.some((demande) => !!demande.idBanque);
}
