import { DemandeSignatureErrorType } from "@domain/enum/demandeSignatureErrorType";
import { DocusignConfigurationError } from "@domain/enum/docusignConfigurationError";
import { isSomeEnum } from "@domain/utils/enumUtils";

export interface DemandeSignatureConfigurationError {
  type: DemandeSignatureErrorType.BAD_CONFIGURATION;
  docusignConfigurationErrorList: DocusignConfigurationError[];
}

export type DemandeSignatureCommonErrorType = Exclude<
  DemandeSignatureErrorType,
  DemandeSignatureErrorType.BAD_CONFIGURATION
>;

export interface DemandeSignatureCommonError {
  type: DemandeSignatureCommonErrorType;
}

export type DemandeSignatureError =
  | DemandeSignatureConfigurationError
  | DemandeSignatureCommonError;

export function isDemandeSignatureCommonErrorType(
  value: unknown
): value is DemandeSignatureCommonErrorType {
  return (
    isSomeEnum(DemandeSignatureErrorType)(value) &&
    value !== DemandeSignatureErrorType.BAD_CONFIGURATION
  );
}
