export enum NeomiEvent {
  CHANGE = "change",
  CANCELLED = "cancelled",
  VALIDATED = "validated",
  SIGNATURE = "sign",
  UPLOAD = "upload",
  NEXT = "next",
  BACK = "back",
  CHECK = "check",
  UPDATE = "update",
  DELETE = "delete",
  DECREMENT = "decrement",
  INCREMENT = "increment",
  TOGGLE = "toggle",
  INPUT = "input",
  CLICK = "click",
  UPDATE_USER_NAME = "update-user-name",
  UPDATE_USER_EMAIL = "update-user-email",
  UPDATE_USER_ID_GROUP = "update-user-id-group",
  HIDE_SIDE_PANEL = "hide-side-panel",
  FILTER_BOARD = "filter-board",
  FILE_ADDED = "file-added",
  ADD_DOCUMENT_TO_DEMANDE = "add-document-to-demande",
  ADD_MEMBER_TO_OPERATION = "add-member-operation",
  DOWNLOAD_DOCUMENT = "download-document",
  SAVE_EDITING_DOCUMENT = "save-editing-document",
  SAVED_EDITING_DOCUMENT = "saved-editing-document",
  PREVIEW_DOCUMENT = "preview-document",
  CHOOSE_VISIBLE_CARD = "choose-visible-card",
  UPDATE_FIELD = "update-field",
  CLOSE_MODAL = "close-modal",
  ROLLBACK_TO_SIGNATURE = "rollback-to-signature",
  ROLLBACK_TO_CO_CONSTRUCTION = "rollback-to-co-construction",
  SHOW_EDITION_MODE = "show-edition-mode",
  START_VALIDATION = "start-validation",
  CANCEL_VALIDATION = "cancel-validation",
  UPDATE_TOKEN = "update-token",
  NAME_CHANGED = "name-changed",
  MANUAL_CATEGORY_CHANGED = "manual-category-changed",

  UPDATE_SURETE = "update-surete",
  CHANGE_ANCHOR = "change-anchor",
  EDITION_MODE = "in-edition-mode",
  EDITION_READY = "edition-ready",
  SHOW_PREVIOUS_VERSIONS_LIST = "show-previous-version-list",
  CELL_CLICKED = "cell-clicked",
  CELL_HOVERED = "cell-hovered",
  CELL_LEFT = "cell-left",
  CANCEL_HEADER = "cancel-header",
  CANCEL_COLUMN = "cancel-column",
  CLEAR_SELECTED_CELLS = "clear-selected-cells",
  FORMAT_EURO_AMOUNT = "format-euro-amount",
  EDIT_DOCUMENT = "edit-document",
  UNBLOCK_DOCUMENT_EDITION = "unblock-document-edition",
  DELETE_DOCUMENT = "delete-document",
  ADD_AMOUNT_TO_DEMANDE = "add-amount-to-demande",
  ADD_USER_TO_LIST = "add-user-to-list",
  BULK_ADD_USERS_TO_LIST = "bulk-add-users-to-list",
  REMOVE_USER_FROM_LIST = "remove-user-from-list",
  REMOVE_LAST_USER_FROM_LIST = "remove-last-user-from-list",
  TOGGLE_USER_ADD_BUTTON = "toggle-user-add-button",
  DELETE_USER = "delete-user",
  SHOW_ERROR = "show-error",
  UPDATE_USER_ON_LOGIN = "update_user_on_login",
  UPDATE_ID_CMP = "update-id-cmp",
  SHOW_MODAL = "show-modal",
  SHOW_SIDE_PANEL = "show-side-panel",
  TOGGLE_ROLE = "toggle-role",
  CHOSE_SIGN_METHOD = "chose-sign-method",
  SET_CUSTOM_USERNAME = "set-custom-username",
  DROPDOWN_SELECT = "dropdown-select",
  SEND_MESSAGE = "send-message",
  UPDATE_MESSAGE = "update-message",
  TAG_USER = "tag-user",
  SET_TAG_USER = "set-tag-user",
  LETTRE_ACCORD_SIGNED = "lettre-accord-signed",
  DEMANDE_SIGNED = "demande-signed",
  SWAP_ELEMENTS = "swap-elements",
  TOGGLE_OPERATION_DETAILS = "toggle-operation-details",

  EDIT_TRANCHE = "edit-tranche",
  UPDATE_TRANCHE_BINDING = "update-tranche-binding",

  UPDATE_ATTESTATION = "update-attestation",
  UPDATE_CONVENTION = "update-convention",
  TOGGLE_SUIVI_COMM_EDIT = "toggle_suivi_comm_edit",
  TOGGLE_DEMANDE_EDIT = "toggle-demande-edit",
  DELETE_ATTESTATION_SECONDAIRE = "delete-attestation-secondaire",
  DOWNLOAD_NEW_DOCUMENTS = "download_new_documents",
  DOWNLOAD_ALL_DOCUMENTS = "download_all_documents",

  EDIT_BROUILLON_DOCUMENT = "edit-brouillon-document",
  EDIT_MAIN_DOCUMENT = "edit-main-document",
  EDIT_DOCUMENT_MODIFIED = "edit-document-modified",

  EXPAND = "expand",

  TOGGLE_DEMANDE_SELECTED = "toggle-demande-selected",
  CHOOSE_OPTION = "choose-option",

  EDIT_SURETE = "edit-surete",
  DELETE_SURETE = "delete-surete",

  EDIT_NAME_SUIVI_TRAVAUX = "edit-name-suivi-travaux",
  EDIT_DATE_SUIVI_TRAVAUX = "edit-date-suivi-travaux",
  DELETE_SUIVI_TRAVAUX = "delete-suivi-travaux",

  STARTED_EDITING_DOCUMENT = "started-editing-document",
  CLOSE_EDIT_DOCUMENT_MODAL = "close-edit-document-modal",
  BROUILLON_DOCUMENT_INTERVAL = "brouillon-document-interval",

  EDIT_LETTRE_ACCORD = "edit-lettre-accord",

  REMIND_SIGNATURE = "remind-signature",

  TOGGLE_CHAT_WINDOW_SIZE = "toggle-chat-window-size",
  TOGGLE_CHAT_COLLAPSE_STATE = "toggle-chat-collapse-state",

  SELECT_PARTICIPANT_CHAT = "select-participant-chat",
  SELECT_ALL_PARTICIPANT_CHAT = "select-all-participant-chat",
  DELETE_ACCORD_COMMERCIAL = "deleteAccordCommercial",

  UPDATE_SUIVI_BUDGET_DOCUMENT = "update-suivi-budget-document",
  SAVE_SUIVI_BUDGET_MODELE = "save-suivi-budget-modele",
  DISSOCIATION_DES_TRANCHES = "dissociation-des-tranches",
  DELETE_OPERATION = "delete-operation",
  EDIT_OPERATION_NAME = "edit-name-operation",
  ARCHIVE_OPERATION = "archive-operation",
  UNARCHIVE_OPERATION = "unarchive-operation",
  ENCOURS_SEARCH_CHANGE = "encours-search-change",
}
