import { RouteName } from "@/routeName";
import { getSetupContext } from "@/utils/vueUtils";
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/accueil",
      name: RouteName.DASHBOARD,
      component: () => import("@/views/DashboardView.vue"),
    },
    {
      path: "/accueil/homme-de-art",
      name: RouteName.DASHBOARD_HOMME_DE_ART,
      component: () => import("@/views/DashboardHommeDeArtView.vue"),
    },
    {
      path: "/homme-de-art/operations/:idOperation/demandes/:idDemande",
      name: RouteName.HOMME_DE_ART_DEMANDE,
      component: () => import("@/views/HommeDeArtDemandeView.vue"),
    },
    {
      path: "/operations/:idOperation",
      component: () => import("@/views/OperationView.vue"),
      props: true,
      children: [
        {
          path: "",
          name: "operation",
          component: () =>
            import("@/components/pool/RedirectMainOperationPage.vue"),
          props: true,
        },
        {
          path: "demandes",
          name: RouteName.OPERATION_DEMANDES,
          component: () => import("@/views/OperationDemandeListView.vue"),
          props: true,
        },
        {
          path: "demandes/:idDemande",
          name: RouteName.OPERATION_DEMANDE,
          component: () => import("@/views/DemandeView.vue"),
          props: (route: RouteLocationNormalized) => {
            return {
              ...setSignatureQueryParam(route),
              idDemande: route.params.idDemande,
            };
          },
        },

        {
          path: "lettres-accord/:idLettreAccord",
          name: RouteName.OPERATION_LETTRE_ACCORD,
          component: () => import("@/views/LettreAccordView.vue"),
          props: (route: RouteLocationNormalized) => {
            return setSignatureQueryParam(route);
          },
        },
        {
          path: "documents",
          name: RouteName.OPERATION_DOCUMENTS,
          component: () => import("@/views/OperationDocumentsView.vue"),
          props: true,
        },
        {
          path: "equipe",
          name: RouteName.OPERATION_EQUIPE,
          component: () => import("@/views/OperationTeamView.vue"),
          props: true,
        },
        {
          path: "pool",
          name: RouteName.OPERATION_POOL,
          component: () => import("@/views/OperationPoolView.vue"),
          props: true,
        },
        {
          path: "tiers",
          name: RouteName.OPERATION_TIERS,
          component: () => import("@/views/TiersOperationDemandeListView.vue"),
          props: true,
        },
        {
          path: "suivi",
          name: RouteName.OPERATION_SUIVI,
          component: () => import("@/views/OperationSuiviView.vue"),
          props: true,
        },
        {
          path: "accords-commerciaux/:idPoolAccordCommercial",
          name: RouteName.CONTRAT_SOUS_PARTICIPATION,
          component: () => import("@/views/AccordCommercialView.vue"),
          props: true,
        },

        {
          path: "suretes",
          name: RouteName.OPERATION_SURETES,
          component: () => import("@/views/OperationSureteListView.vue"),
          props: true,
        },
        {
          path: "suretes/:idSurete",
          name: RouteName.OPERATION_SURETE,
          component: () => import("@/views/SureteView.vue"),
          props: (route: RouteLocationNormalized) => {
            return {
              ...setSignatureQueryParam(route),
              idSurete: route.params.idSurete,
            };
          },
        },
      ],
    },
    {
      path: "/societes-support",
      name: "societes-support",
      component: () => import("@/views/SocieteSupportListView.vue"),
    },
    {
      path: "/societes-support/:idSocieteSupport",
      component: () => import("@/views/SocieteSupportView.vue"),
      props: true,
      children: [
        {
          path: "",
          redirect: { name: RouteName.SOCIETE_SUPPORT_BANQUES },
        },
        {
          path: "banques",
          props: true,
          name: RouteName.SOCIETE_SUPPORT_BANQUES,
          component: () => import("@/views/SocieteSupportBanqueView.vue"),
        },
        {
          path: "operations",
          props: true,
          name: RouteName.SOCIETE_SUPPORT_OPERATIONS,
          component: () => import("@/views/SocieteSupportOperationView.vue"),
        },
        {
          path: "documents",
          props: true,
          name: RouteName.SOCIETE_SUPPORT_DOCUMENTS,
          component: () => import("@/views/SocieteSupportDocumentsView.vue"),
        },
        {
          path: "suretes",
          props: true,
          name: RouteName.SOCIETE_SUPPORT_SURETES,
          component: () => import("@/views/SocieteSupportSureteListView.vue"),
        },
        {
          path: "suretes/:idSurete",
          name: RouteName.SOCIETE_SUPPORT_SURETE,
          component: () => import("@/views/SureteView.vue"),
          props: (route: RouteLocationNormalized) => {
            return {
              ...setSignatureQueryParam(route),
              idSurete: route.params.idSurete,
            };
          },
        },
      ],
    },
    {
      path: "/suivis",
      component: () => import("@/views/CommercialisationView.vue"),
      children: [
        {
          path: "",
          redirect: { name: RouteName.COMMERCIALISATION_SUIVI },
        },
        {
          path: "commercialisation",
          name: RouteName.COMMERCIALISATION_SUIVI,
          component: () => import("@/views/CommercialisationSuiviView.vue"),
        },
        {
          path: "commercialisation/promoteurs/:idPromoteur",
          name: RouteName.COMMERCIALISATION_SUIVI_BANQUE,
          props: true,
          component: () =>
            import("@/views/CommercialisationSuiviBanqueItemView.vue"),
        },
        {
          path: "travaux",
          name: RouteName.COMMERCIALISATION_SUIVI_TRAVAUX,
          props: true,
          component: () => import("@/views/CommercialisationSuiviTravaux.vue"),
        },
        {
          path: "encours",
          name: RouteName.COMMERCIALISATION_SUIVI_ENCOURS,
          props: true,
          component: () => import("@/views/SuiviGlobalEncoursView.vue"),
        },
      ],
    },
    {
      path: "/reporting",
      name: RouteName.REPORTING,
      component: () => import("@/views/ReportingView.vue"),
    },
    {
      path: "/info",
      name: RouteName.INFO,
      component: () => import("@/views/InfoView.vue"),
    },
    {
      path: "/help",
      component: () => import("@/views/CentreAideView.vue"),
      children: [
        {
          path: "",
          redirect: {
            name: RouteName.CENTRE_AIDE,
          },
        },
        {
          path: "home",
          name: RouteName.CENTRE_AIDE,
          component: () => import("@/views/CentreAide/CentreAideHome.vue"),
        },
        {
          path: "premiers-pas",
          component: () =>
            import("@/views/CentreAide/CentreAidePremiersPas.vue"),
        },
        {
          path: "edition-en-ligne",
          component: () =>
            import("@/views/CentreAide/CentreAideEditionEnLigne.vue"),
        },
        {
          path: "conditions-mise-en-place",
          component: () => import("@/views/CentreAide/CentreAideCMP.vue"),
        },
        {
          path: "actes-secondaires",
          component: () =>
            import("@/views/CentreAide/CentreAideActesSecondaires.vue"),
        },
        {
          path: "invitations-tiers",
          component: () =>
            import("@/views/CentreAide/CentreAideInvationsTiers.vue"),
        },
        {
          path: "pool-et-syndication",
          component: () =>
            import("@/views/CentreAide/CentreAidePoolEtSyndication.vue"),
        },
        {
          path: "partenaires-hors-plateforme",
          component: () =>
            import("@/views/CentreAide/CentreAidePartenaireHorsPlateforme.vue"),
        },
        {
          path: "suivi",
          component: () => import("@/views/CentreAide/CentreAideSuivi.vue"),
        },

        {
          path: "questionnaire-esg",
          component: () =>
            import("@/views/CentreAide/CentreAideQuestionnaireEsg.vue"),
        },
        {
          path: "surete",
          component: () => import("@/views/CentreAide/CentreAideSurete.vue"),
        },
        {
          path: "suivi-travaux-massif",
          component: () =>
            import("@/views/CentreAide/CentreAideSuiviTravauxMassif.vue"),
        },
        {
          path: "suivi-budget",
          component: () =>
            import("@/views/CentreAide/CentreAideSuiviBudget.vue"),
        },
      ],
    },

    {
      path: "/rgpd",
      name: RouteName.RGPD,
      component: () => import("@/views/RGPDView.vue"),
    },
    {
      path: "/annuaire",
      component: () => import("@/views/AnnuaireView.vue"),
      children: [
        {
          path: "",
          redirect: {
            name: RouteName.ANNUAIRE_HOME,
          },
        },
        {
          path: "home",
          name: RouteName.ANNUAIRE_HOME,
          component: () => import("@/views/AnnuaireHomeView.vue"),
        },
        {
          path: "gestion-pool",
          name: RouteName.ANNUAIRE_GESTION_POOL,
          component: () => import("@/views/AnnuaireGestionPoolView.vue"),
        },
      ],
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/UserProfileView.vue"),
    },
    {
      path: "/suivi-facturation",
      name: RouteName.SUIVI_FACTURATION,
      component: () => import("@/views/SuiviFacturationView.vue"),
    },
    {
      path: "/",
      redirect: "/accueil",
    },
    {
      path: "/unAuthorized",
      name: "unAuthorized",
      component: () => import("@/views/UnAuthorized.vue"),
    },
    {
      path: "/resource-not-found",
      name: "resourceNotFound",
      component: () => import("@/views/ResourceNotFound.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      component: () => import("@/views/ResourceNotFound.vue"),
    },
  ],
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 500);
    });
  },
});

router.beforeEach(async (to, _from, next) => {
  document.title = "Neomi";
  const { routeStore, keycloakStore } = getSetupContext();
  routeStore.SetActiveRoute(to);
  if (keycloakStore.isAuthenticated) {
    next();
  } else {
    await keycloakStore.authenticate(next);
  }
});

function setSignatureQueryParam(route: RouteLocationNormalized): {
  showSignatureSuccess: boolean;
  showSignatureFailure: boolean;
} {
  const showSignatureSuccess: boolean = route.query.signature === "ok";
  const showSignatureFailure: boolean = route.query.signature === "ko";
  return {
    showSignatureSuccess,
    showSignatureFailure,
  };
}

export default router;
