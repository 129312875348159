import { DeltaOpInsertImageValidator } from "@domain/dto/delta/deltaOpValidator";
import { ChatMentionType } from "../enum/chatMentionType";

export type QuillMention = {
  id: string;
  value: string;
  type: ChatMentionType;
};

export type DeltaQuillMention = {
  mention: QuillMention;
};

export function isDeltaInsertAMention(
  insert: unknown,
): insert is DeltaQuillMention {
  return (
    typeof insert === "object" &&
    insert !== null &&
    (insert as DeltaQuillMention).mention !== undefined
  );
}

export function isDeltaInsertImage(
  insert: unknown,
): insert is DeltaOpInsertImageValidator {
  return (
    typeof insert === "object" &&
    insert !== null &&
    (insert as DeltaOpInsertImageValidator).image !== undefined
  );
}
