export enum RouteName {
  DASHBOARD = "dashboard",

  DASHBOARD_HOMME_DE_ART = "dashboard/homme-de-art",
  HOMME_DE_ART_DEMANDE = "demandes/homme-de-art",

  OPERATION_DEMANDES = "operation/demandes",
  OPERATION_DEMANDE = "operation/demande",
  OPERATION_LETTRE_ACCORD = "operation/lettres-accord",
  OPERATION_DOCUMENTS = "operation/documents",
  OPERATION_EQUIPE = "operation/equipe",
  OPERATION_POOL = "operation/pool",
  OPERATION_TIERS = "operation/tiers",
  OPERATION_SUIVI = "operation/suivi",
  OPERATION_SURETES = "operation/suretes",
  OPERATION_SURETE = "operation/surete",
  CONTRAT_SOUS_PARTICIPATION = "operation/accords-commerciaux",

  SOCIETE_SUPPORT_LIST = "societes-support",
  SOCIETE_SUPPORT_BANQUES = "societeSupport/banques",
  SOCIETE_SUPPORT_OPERATIONS = "societeSupport/operations",
  SOCIETE_SUPPORT_DOCUMENTS = "societeSupport/documents",
  SOCIETE_SUPPORT_SURETES = "societeSupport/suretes",
  SOCIETE_SUPPORT_SURETE = "societeSupport/surete",

  COMMERCIALISATION_SUIVI = "commercialisation/suivi",
  COMMERCIALISATION_SUIVI_BANQUE = "commercialisation/suivi-banque",
  COMMERCIALISATION_SUIVI_TRAVAUX = "commercialisation/suivi-travaux",
  COMMERCIALISATION_SUIVI_ENCOURS = "commercialisation/suivi-encours",

  REPORTING = "reporting",

  RGPD = "rgpd",
  INFO = "info",
  CENTRE_AIDE = "centreAide",
  ANNUAIRE = "annuaire",
  ANNUAIRE_HOME = "annuaire/home",
  ANNUAIRE_GESTION_POOL = "annuaire/gestion-pool",

  SUIVI_FACTURATION = "suivi-facturation",

  //TODO: A supprimer
  COMMERCIALISATION_SUIVI_DATATABLE = "commercialisation/suivi-datatable",
}
