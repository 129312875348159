import { DocusignConfigurationError } from "@domain/enum/docusignConfigurationError";
import { SureteSignatureErrorType } from "@domain/enum/sureteSignatureErrorType";
import { isSomeEnum } from "@domain/utils/enumUtils";

export interface SureteSignatureConfigurationError {
  type: SureteSignatureErrorType.BAD_CONFIGURATION;
  docusignConfigurationErrorList: DocusignConfigurationError[];
}

export type SureteSignatureCommonErrorType = Exclude<
  SureteSignatureErrorType,
  SureteSignatureErrorType.BAD_CONFIGURATION
>;

export interface SureteSignatureCommonError {
  type: SureteSignatureCommonErrorType;
}

export type SureteSignatureError =
  | SureteSignatureConfigurationError
  | SureteSignatureCommonError;

export function isSureteSignatureCommonErrorType(
  value: unknown
): value is SureteSignatureCommonErrorType {
  return (
    isSomeEnum(SureteSignatureErrorType)(value) &&
    value !== SureteSignatureErrorType.BAD_CONFIGURATION
  );
}
