import { DocusignConfigurationError } from "@domain/enum/docusignConfigurationError";
import { LettreAccordSignatureErrorType } from "@domain/enum/lettreAccordSignatureErrorType";
import { isSomeEnum } from "@domain/utils/enumUtils";

export interface LettreAccordSignatureConfigurationError {
  type: LettreAccordSignatureErrorType.BAD_CONFIGURATION;
  docusignConfigurationErrorList: DocusignConfigurationError[];
}

export type LettreAccordSignatureCommonErrorType = Exclude<
  LettreAccordSignatureErrorType,
  LettreAccordSignatureErrorType.BAD_CONFIGURATION
>;

export interface LettreAccordSignatureCommonError {
  type: LettreAccordSignatureCommonErrorType;
}

export type LettreAccordSignatureError =
  | LettreAccordSignatureConfigurationError
  | LettreAccordSignatureCommonError;

export function isLettreAccordSignatureCommonErrorType(
  value: unknown
): value is LettreAccordSignatureCommonErrorType {
  return (
    isSomeEnum(LettreAccordSignatureErrorType)(value) &&
    value !== LettreAccordSignatureErrorType.BAD_CONFIGURATION
  );
}
