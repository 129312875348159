import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { DemandeCreationRequest } from "@domain/dto/demandeCreationRequest";
import { OperationDocument } from "@domain/dto/operationDocument";
import { DemandeUpdateRequest } from "@domain/dto/demandeUpdateRequest";
import { Demande } from "@/domain/demande";
import { DemandeSignataireCreateRequest } from "@domain/dto/demandeSignataireCreateRequest";
import { WorkflowRollbackType } from "@/domain/enum/workflowRollbackType";
import { DocumentStatus } from "@domain/enum/documentStatus";
import { DemandeDocumentValidationRequest } from "@domain/dto/demandeDocumentValidationRequest";
import { CMPCreationRequest } from "@domain/dto/cmpCreationRequest";
import { CmpUpdateRequest } from "@domain/dto/cmpUpdateRequest";
import { CMPStatus } from "@domain/enum/CMPStatus";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { DocusignConfigurationError } from "@domain/enum/docusignConfigurationError";
import { DemandeEncoursResponse } from "@domain/dto/demandeEncours";
import { DemandeEncoursCreateRequest } from "@domain/dto/demandeEncoursCreateRequest";
import { DemandeSignatureCommonErrorType } from "@domain/dto/errors/demandeSignatureError";
import { OperationDemandeEncours } from "@domain/dto/operationDemandeEncours";

export default {
  create(
    demandes: DemandeCreationRequest[],
    idOperation: string
  ): Promise<ApiResponse<Demande[]>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/demandes`,
      demandes
    );
  },
  createAvenant(
    avenant: DemandeCreationRequest,
    idOperation: string
  ): Promise<ApiResponse<Demande>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/avenants`,
      avenant
    );
  },
  createConditions(
    conditionCreationRequest: CMPCreationRequest,
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<Demande>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/demandes/${idDemande}/conditions`,
      conditionCreationRequest
    );
  },
  updateConditions(
    conditionUpdateRequestList: CmpUpdateRequest[],
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<Demande>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/demandes/${idDemande}/conditions`,
      conditionUpdateRequestList
    );
  },

  createDocumentValidation(
    idOperation: string,
    idDemande: string,
    documentValidationRequest: DemandeDocumentValidationRequest,
    file: File
  ): Promise<ApiResponse<PendingDocument[]>> {
    const formData: FormData = new FormData();

    formData.append(
      "shouldBanquierValidate",
      `${documentValidationRequest.shouldBanquierValidate}`
    );
    formData.append(
      "shouldPromoteurValidate",
      `${documentValidationRequest.shouldPromoteurValidate}`
    );
    formData.append("fileName", `${documentValidationRequest.fileName}`);

    formData.append("documentValidation", file);

    return apiRequestThrottle.post(
      `/operations/${idOperation}/demandes/${idDemande}/document-validation`,
      formData
    );
  },

  fetchDemande(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<Demande>> {
    return apiRequest.get(`/operations/${idOperation}/demandes/${idDemande}`);
  },

  fetchDemandeProject(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<OperationDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/documents?document_status=${DocumentStatus.PROJET}`
    );
  },

  fetchDemandeSignedAct(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<OperationDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/documents?document_status=${DocumentStatus.SIGNED_ACTE}`
    );
  },

  fetchDemandeCertificate(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<OperationDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/documents?document_status=${DocumentStatus.CERTIFICATE}`
    );
  },

  fetchDemandeDocumentList(
    idOperation: string,
    idDemande: string,
    status: DocumentStatus
  ): Promise<ApiResponse<OperationDocument[]>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/documents?document_status=${status}`
    );
  },

  downloadDemandeDocumentValidation(
    idOperation: string,
    idDemande: string,
    idDemandeDocumentValidation: string
  ): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/document-validation/${idDemandeDocumentValidation}/download`,
      { responseType: "blob" }
    );
  },

  deleteDemandeDocumentValidation(
    idOperation: string,
    idDemande: string,
    idDocumentValidation: string
  ): Promise<ApiResponse<void>> {
    return apiRequest.delete(
      `/operations/${idOperation}/demandes/${idDemande}/document-validation/${idDocumentValidation}`
    );
  },

  deleteDemande(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<string[]>> {
    return apiRequest.delete(
      `/operations/${idOperation}/demandes/${idDemande}`
    );
  },
  deleteAttestation(
    idOperation: string,
    idAttestation: string
  ): Promise<ApiResponse<void>> {
    return apiRequest.delete(
      `/operations/${idOperation}/attestations/${idAttestation}`
    );
  },
  deleteConditions(
    idOperation: string,
    idDemande: string,
    conditionIdList: string[]
  ): Promise<ApiResponse<Demande>> {
    return apiRequest.delete(
      `/operations/${idOperation}/demandes/${idDemande}/conditions`,
      { data: { conditionIdList } }
    );
  },

  update(
    idOperation: string,
    idDemande: string,
    demandeUpdateRequest: DemandeUpdateRequest
  ): Promise<ApiResponse<Demande>> {
    return apiRequest.patch(
      `/operations/${idOperation}/demandes/${idDemande}`,
      demandeUpdateRequest,
      {
        validateStatus(status) {
          return status === 200 || status === 409;
        },
      }
    );
  },

  updateAttestationList(
    idOperation: string,
    demandeUpdateRequest: DemandeUpdateRequest[]
  ): Promise<ApiResponse<Demande>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/attestations`,
      demandeUpdateRequest,
      {
        validateStatus(status) {
          return status === 200 || status === 409;
        },
      }
    );
  },

  updateDemandeSignatureConfiguration(
    idOperation: string,
    idDemande: string,
    demandeSignataireCreateRequest: DemandeSignataireCreateRequest
  ): Promise<ApiResponse<Demande | null>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/demandes/${idDemande}/signataires`,
      demandeSignataireCreateRequest,
      {
        validateStatus: function (status) {
          return status === 201 || status === 409;
        },
      }
    );
  },

  initializeSignature(
    idOperation: string,
    idDemande: string
  ): Promise<
    ApiResponse<
      Demande | DocusignConfigurationError[] | DemandeSignatureCommonErrorType
    >
  > {
    return apiRequest.post(
      `/operations/${idOperation}/demandes/${idDemande}/signature/initialize`,
      {},
      {
        validateStatus: function (status: number) {
          return [200, 400, 409].includes(status);
        },
      }
    );
  },

  startDemandeSignatureCeremonie(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<string>> {
    return apiRequest.post(
      `/operations/${idOperation}/demandes/${idDemande}/signature/start-ceremonie`
    );
  },

  rollbackWorkflow(
    idOperation: string,
    idDemande: string,
    rollbackType: WorkflowRollbackType
  ): Promise<ApiResponse<string>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/demandes/${idDemande}/workflow/rollback`,
      { rollbackType }
    );
  },

  validateCMP(
    idOperation: string,
    idDemande: string,
    idCondition: string,
    status: CMPStatus
  ): Promise<ApiResponse<Demande>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/demandes/${idDemande}/conditions/${idCondition}/validate`,
      {
        status,
      }
    );
  },

  remindSignatureToRecipient(
    idOperation: string,
    idDemande: string,
    idRecipient: number
  ): Promise<ApiResponse<Demande>> {
    return apiRequestThrottle.put(
      `/operations/${idOperation}/demandes/${idDemande}/signature/remind/${idRecipient}`,
      {},
      {
        validateStatus: (status) => [201, 400, 403, 409].includes(status),
      }
    );
  },

  async fetchDemandeEcours(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<DemandeEncoursResponse | null>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/encours`
    );
  },

  async fetchOperationDemandeEncoursList(): Promise<
    ApiResponse<OperationDemandeEncours[]>
  > {
    return apiRequest.get(`/suivi/encours`, {
      validateStatus(status) {
        return status === 200;
      },
    });
  },

  async createDemandeEncours(
    idOperation: string,
    idDemande: string,
    encoursCreateRequest: DemandeEncoursCreateRequest
  ): Promise<ApiResponse<DemandeEncoursResponse>> {
    return apiRequest.post(
      `/operations/${idOperation}/demandes/${idDemande}/encours`,
      encoursCreateRequest
    );
  },

  fetchExcelGlobalEncoursExport(): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get("/suivi/encours/export");
  },
};
