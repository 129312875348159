import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { LettreAccordHeavy } from "@domain/dto/lettreAccordHeavy";
import { LettreAccordLight } from "@domain/dto/lettreAccordLight";
import { OperationDocument } from "@domain/dto/operationDocument";
import {
  buildLettreAccordNewSignataireBanqueCountCreateRequest,
  buildLettreAccordNewSignatairePromoteurCountCreateRequest,
  createLettreAccordHeavy,
} from "@/utils/lettreAccordUtils";
import { createOperationDocument } from "@/utils/documentUtils";
import { SignataireHeavy } from "@domain/dto/signataireHeavy";
import lettreAccordApi from "@/api/lettreAccordApi";
import { LettreAccordCreationRequest } from "@domain/dto/lettreAccordCreationRequest";
import { useOperationStore } from "@/store/operationModule.pinia";
import { toastError, toastSuccess, toastWarn } from "@/utils/toastUtils";
import {
  computeExceedFileBatchSizeError,
  isFileBatchUploadable,
} from "@/utils/uploadFileUtils";
import { StatusUpdateRequest } from "@domain/dto/statusUpdateRequest";
import { SignMethod } from "@domain/enum/signMethod";
import { LettreAccordUpdateRequest } from "@domain/dto/lettreAccordUpdateRequest";
import { LettreAccordStep } from "@domain/enum/lettreAccordStep";
import { WorkflowRollbackType } from "@/domain/enum/workflowRollbackType";
import { LettreAccordSignataireCreateRequest } from "@domain/dto/lettreAccordSignataireCreateRequest";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { formatDocusignConfigurationErrorList } from "@/utils/signatureUtils";
import { isDocusignConfigurationErrorList } from "@domain/utils/docusignUtils";
import { isLettreAccordSignatureCommonErrorType } from "@domain/dto/errors/lettreAccordSignatureError";
import { LettreAccordSignatureErrorType } from "@domain/enum/lettreAccordSignatureErrorType";

type LettreAccordStateType = {
  lettreAccord: LettreAccordHeavy;
  lettresAccord: LettreAccordLight[];
  lettreAccordProjectDocument: OperationDocument;
  lettreAccordActeDocument: OperationDocument;
  lettreAccordCertificateDocument: OperationDocument;
};

export const useLettreAccordStore = defineStore(ModuleType.LettreAccord, {
  state: (): LettreAccordStateType => ({
    lettreAccord: createLettreAccordHeavy(), // Current lettre d'accord
    lettresAccord: [],
    lettreAccordProjectDocument: createOperationDocument(),
    lettreAccordActeDocument: createOperationDocument(),
    lettreAccordCertificateDocument: createOperationDocument(),
  }),
  getters: {
    getLettreAccord(state): LettreAccordHeavy {
      return state.lettreAccord;
    },

    getOperationLettresAccord(state): LettreAccordLight[] {
      return state.lettresAccord;
    },

    getLettreAccordProjectDocument(state): OperationDocument {
      return state.lettreAccordProjectDocument;
    },

    getLettreAccordActeDocument(state): OperationDocument {
      return state.lettreAccordActeDocument;
    },

    getLettreAccordCertificateDocument(state): OperationDocument {
      return state.lettreAccordCertificateDocument;
    },

    hasLettreAccord(state): boolean {
      return state.lettreAccord.id !== "";
    },

    hasLettreAccordProjectDocument(state): boolean {
      return !!state.lettreAccordProjectDocument.id;
    },

    getHasLettreAccordEnvelope(state): boolean {
      return !!state.lettreAccord.idEnvelope;
    },

    getLettreAccordSignatureConfigurationReady(state): boolean {
      return (
        state.lettreAccord.signataireList?.length ===
        state.lettreAccord.signatairePromoteurCount +
          state.lettreAccord.signataireBanqueCount
      );
    },

    getLettreAccordSignaturePromoteurCount(state): number {
      return state.lettreAccord.signatairePromoteurCount;
    },

    getLettreAccordSignatureBanqueCount(state): number {
      return state.lettreAccord.signataireBanqueCount;
    },

    getLettreAccordSignataireList(state): SignataireHeavy[] {
      return state.lettreAccord.signataireList;
    },
  },
  actions: {
    // Mutations
    SetLettreAccord(lettreAccord: LettreAccordHeavy): void {
      this.lettreAccord = lettreAccord;
    },

    SetLettresAccord(lettresAccord: LettreAccordLight[]): void {
      this.lettresAccord = lettresAccord;
    },

    SetLettreAccordProjectDocument(
      lettreAccordProjectDocument: OperationDocument
    ): void {
      this.lettreAccordProjectDocument = lettreAccordProjectDocument;
    },

    SetLettreAccordActeDocument(
      lettreAccordActeDocument: OperationDocument
    ): void {
      this.lettreAccordActeDocument = lettreAccordActeDocument;
    },

    SetLettreAccordCertificateDocument(
      lettreAccordCertificateDocument: OperationDocument
    ): void {
      this.lettreAccordCertificateDocument = lettreAccordCertificateDocument;
    },

    ResetLettreAccordProjectDocument(): void {
      this.lettreAccordProjectDocument = createOperationDocument();
    },

    ResetLettreAccordActeDocument(): void {
      this.lettreAccordActeDocument = createOperationDocument();
    },

    ResetLettreAccordCertificateDocument(): void {
      this.lettreAccordCertificateDocument = createOperationDocument();
    },

    ResetLettreAccord(): void {
      this.lettreAccord = createLettreAccordHeavy();
    },

    ResetOperationLettresAccord(): void {
      this.lettresAccord = [];
    },

    // Actions
    async fetchLettreAccord(payload: {
      idOperation: string;
      idLettreAccord: string;
    }): Promise<void> {
      const lettreAccord = await lettreAccordApi.fetchLettreAccord(
        payload.idOperation,
        payload.idLettreAccord
      );
      if (lettreAccord.data) {
        this.SetLettreAccord(lettreAccord.data);
      } else {
        this.ResetLettreAccord();
      }
    },

    async fetchOperationLettresAccord(idOperation: string): Promise<void> {
      const lettresAccord =
        await lettreAccordApi.fetchOperationLettresAccord(idOperation);
      this.SetLettresAccord(lettresAccord.data);
    },

    async createLettreAccord(
      lettreAccord: LettreAccordCreationRequest
    ): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const errorMessage = "Échec lors de la création de la lettre d'accord";
      lettreAccordApi
        .create(lettreAccord, idOperation)
        .then((result) => {
          if (result.status === 201) {
            toastSuccess("Lettre d'accord créée avec succès");
            this.fetchOperationLettresAccord(idOperation);
          } else {
            toastError(errorMessage);
          }
        })
        .catch(() => toastError(errorMessage));
    },

    async fetchLettreAccordProjectDocument(): Promise<void> {
      const idOperation: string = useOperationStore().getOperation.id;
      const idLettreAccord: string = this.getLettreAccord.id;
      this.ResetLettreAccordProjectDocument();
      const result = await lettreAccordApi.fetchLettreAccordProjectDocument(
        idOperation,
        idLettreAccord
      );
      if (result.status === 200) {
        this.SetLettreAccordProjectDocument(result.data);
      }
    },

    async fetchLettreAccordDocument(payload: {
      idOperation: string;
      idLettreAccord: string;
    }): Promise<OperationDocument | null> {
      const result = await lettreAccordApi.fetchLettreAccordActeDocument(
        payload.idOperation,
        payload.idLettreAccord
      );
      if (result.status === 200) {
        return result.data;
      }
      return null;
    },

    async fetchLettreAccordActeDocument(): Promise<void> {
      const idOperation: string = useOperationStore().getOperation.id;
      const idLettreAccord = this.getLettreAccord.id;
      this.ResetLettreAccordActeDocument();
      const result = await lettreAccordApi.fetchLettreAccordActeDocument(
        idOperation,
        idLettreAccord
      );
      if (result.status === 200) {
        this.SetLettreAccordActeDocument(result.data);
      }
    },

    async fetchLettreAccordCertificateDocument(): Promise<void> {
      const idOperation: string = useOperationStore().getOperation.id;
      const idLettreAccord: string = this.getLettreAccord.id;
      this.ResetLettreAccordCertificateDocument();
      const result = await lettreAccordApi.fetchLettreAccordCertificateDocument(
        idOperation,
        idLettreAccord
      );
      if (result.status === 200) {
        this.SetLettreAccordCertificateDocument(result.data);
      }
    },

    async uploadLettreAccordProject(
      document: File
    ): Promise<PendingDocument | undefined> {
      if (!isFileBatchUploadable([{ file: document }])) {
        toastError(computeExceedFileBatchSizeError([{ file: document }]));
        return Promise.reject();
      }

      const idOperation: string = useOperationStore().getOperation.id;
      const idLettreAccord = this.getLettreAccord.id;

      const formData = new FormData();
      formData.append("project", document);

      const result = await lettreAccordApi.uploadLettreAccordProjectDocument(
        idOperation,
        idLettreAccord,
        formData
      );

      return result.data.shift();
    },

    async validateLettreAccord(payload: StatusUpdateRequest): Promise<void> {
      const idOperation: string = useOperationStore().getOperation.id;
      const idLettreAccord = this.getLettreAccord.id;
      const result = await lettreAccordApi.validateLettreAccord(
        idOperation,
        idLettreAccord,
        { ...payload }
      );
      if (result.status === 200 && result.data) {
        this.SetLettreAccord(result.data);
      }
    },

    async updateLettreAccordSignataireBanqueCountConfiguration(
      signataireBanqueCount: number
    ): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idLettreAccord = this.getLettreAccord.id;
      const lettreAccordSignataireCreateRequest =
        buildLettreAccordNewSignataireBanqueCountCreateRequest(
          this.lettreAccord,
          signataireBanqueCount
        );
      return lettreAccordApi
        .updateLettreAccordSignatureConfiguration(
          idOperation,
          idLettreAccord,
          lettreAccordSignataireCreateRequest
        )
        .then((result) => {
          if (result.status === 201) {
            this.SetLettreAccord(result.data as LettreAccordHeavy);
          } else if (result.status === 409) {
            toastError(
              "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet acte !"
            );
          }
        })
        .catch(() => {
          toastError("Erreur lors de l'ajout du signataire");
        });
    },

    async updateLettreAccordSignatairePromoteurCountConfiguration(
      signatairePromoteurCount: number
    ): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idLettreAccord = this.getLettreAccord.id;
      const lettreAccordSignataireCreateRequest =
        buildLettreAccordNewSignatairePromoteurCountCreateRequest(
          this.lettreAccord,
          signatairePromoteurCount
        );
      return lettreAccordApi
        .updateLettreAccordSignatureConfiguration(
          idOperation,
          idLettreAccord,
          lettreAccordSignataireCreateRequest
        )
        .then((result) => {
          if (result.status === 201) {
            this.SetLettreAccord(result.data as LettreAccordHeavy);
          } else if (result.status === 409) {
            toastError(
              "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet acte !"
            );
          }
        })
        .catch(() => {
          toastError("Erreur lors de l'ajout du signataire");
        });
    },

    async chooseSignMethod(signMethod: SignMethod): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idLettreAccord = this.getLettreAccord.id;
      const lettreAccordUpdateRequest: LettreAccordUpdateRequest = {
        signMethod: signMethod,
      };
      return lettreAccordApi
        .update(idOperation, idLettreAccord, lettreAccordUpdateRequest)
        .then((response) => {
          toastSuccess("Votre choix de signature a bien été enregistré");
          this.SetLettreAccord(response.data);
        })
        .catch(() => {
          toastError("Votre choix de signature n'a pas pu être enregistré");
        });
    },

    async manuallySign(): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idLettreAccord = this.getLettreAccord.id;
      const lettreAccordUpdateRequest: LettreAccordUpdateRequest = {
        step: LettreAccordStep.COMPLETED,
      };

      return lettreAccordApi
        .update(idOperation, idLettreAccord, lettreAccordUpdateRequest)
        .then((response) => {
          toastSuccess("Votre signature a bien été enregistrée");
          this.SetLettreAccord(response.data);
        })
        .catch(() => {
          toastError("Votre signature n'a pas pu être enregistrée");
        });
    },
    async updateLettreAccord(
      lettreAccordUpdateRequest: LettreAccordUpdateRequest
    ): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idLettreAccord = this.getLettreAccord.id;
      return lettreAccordApi
        .update(idOperation, idLettreAccord, lettreAccordUpdateRequest)
        .then((response) => {
          toastSuccess("Mise à jour avec succès");
          this.SetLettreAccord(response.data);
        })
        .catch(() => {
          toastError(
            "Une erreur est survenue lors de la mise àà jour de la lettre d'accord"
          );
        });
    },
    async updateLettreAccordSignatureNewSignataireConfiguration(
      lettreAccordSignataireCreateRequest: LettreAccordSignataireCreateRequest
    ): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idLettreAccord = this.getLettreAccord.id;

      return lettreAccordApi
        .updateLettreAccordSignatureConfiguration(
          idOperation,
          idLettreAccord,
          lettreAccordSignataireCreateRequest
        )
        .then((result) => {
          if (result.status === 201) {
            this.SetLettreAccord(result.data as LettreAccordHeavy);
            toastSuccess("Signataire ajouté avec succès");
          } else if (result.status === 409) {
            toastError(
              "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet acte !"
            );
          }
        })
        .catch(() => {
          toastError("Erreur lors de l'ajout du signataire");
        });
    },

    async initializeLettreAccordSignature(): Promise<void> {
      try {
        const idOperation = useOperationStore().getOperation.id;
        const idLettreAccord = this.getLettreAccord.id;

        const response = await lettreAccordApi.initializeSignature(
          idOperation,
          idLettreAccord
        );

        if (
          response.status === 200 &&
          !isDocusignConfigurationErrorList(response.data) &&
          !isLettreAccordSignatureCommonErrorType(response.data)
        ) {
          this.SetLettreAccord(response.data);
          return toastSuccess("Enveloppe créée avec succès");
        }

        if (
          response.status === 409 &&
          isLettreAccordSignatureCommonErrorType(response.data)
        ) {
          if (
            response.data === LettreAccordSignatureErrorType.BAD_DOCUMENT_FORMAT
          ) {
            return toastWarn(
              "Impossible de créer l'enveloppe de signature Docusign. Veuillez revenir à la co-construction et charger un fichier PDF.",
              15 * 1000
            );
          }

          return toastWarn(
            "Le document à signer n'est pas au format A4 portrait. Veuillez utiliser la signature libre pour positionner les tampons",
            15 * 1000
          );
        }

        if (
          response.status === 400 &&
          isDocusignConfigurationErrorList(response.data)
        ) {
          return toastError(
            formatDocusignConfigurationErrorList(response.data)
          );
        }

        toastError("Erreur lors de la création de l'enveloppe");
      } catch (error) {
        toastError("Erreur lors de la création de l'enveloppe");
      }
    },

    async startLettreAccordSignatureCeremonie(): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idLettreAccord = this.getLettreAccord.id;
      return lettreAccordApi
        .startLettreAccordSignatureCeremonie(idOperation, idLettreAccord)
        .then((response) => {
          if (response.status === 200) {
            // @ts-ignore
            window.location = response.data;
          } else {
            toastError("Erreur lors de la création de la vue");
          }
        })
        .catch(() => {
          toastError("Erreur lors de la création de la vue");
        });
    },

    async rollbackLettreAccordWorkflow(
      rollbackType: WorkflowRollbackType
    ): Promise<void> {
      const idOperation = this.getLettreAccord.idOperation;
      const idLettreAccord = this.getLettreAccord.id;
      await lettreAccordApi.rollbackWorkflow(
        idOperation,
        idLettreAccord,
        rollbackType
      );
    },

    async remindSignatureToRecipient(
      idOperation: string,
      idLettreAccord: string,
      idRecipient: number
    ): Promise<void> {
      const response = await lettreAccordApi.remindSignatureToRecipient(
        idOperation,
        idLettreAccord,
        idRecipient
      );
      if (response.status != 201) {
        toastError("Erreur lors de la relance");
        return;
      }
      toastSuccess("Le mail de relance a bien été renvoyé");
    },
  },
});
