import { ApiResponse, apiRequest, apiRequestThrottle } from "@/apiRequest";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { createSuiviBudgetDocumentUploadPayloadMetadata } from "@/utils/documentUtils";
import { SuiviBudgetImportCreation } from "@/domain/suiviBudgetImportCreation";
import { SuiviBudgetResponse } from "@domain/dto/suiviBudgetResponse";
import { SuiviBudgetConfigurationCreationRequest } from "@domain/dto/suiviBudgetConfigurationCreationRequest";
import { SuiviBudgetModele } from "@domain/dto/suiviBudgetModele";
import { SuiviBudgetModeleCreationRequest } from "@domain/dto/suiviBudgetModeleCreationRequest";
import { SuiviBudgetConfiguration } from "@domain/dto/suiviBudgetConfiguration";
import { SuiviBudgetModeleWithCompletion } from "@domain/dto/suiviBudgetModeleWithCompletion.ts";

export default {
  async uploadSuiviBudgetPromoteur(
    importCreation: SuiviBudgetImportCreation
  ): Promise<ApiResponse<PendingDocument[]>> {
    const formData: FormData = new FormData();

    formData.append(
      "metadata",
      createSuiviBudgetDocumentUploadPayloadMetadata(importCreation)
    );

    importCreation.documentList.forEach((document) => {
      formData.append("files", document.file);
    });
    return apiRequestThrottle.post(
      `/operations/${importCreation.idOperation}/suivi-budget/budget-promoteur/${importCreation.documentList.length}`,
      formData,
      {
        validateStatus: function (status) {
          return status === 201;
        },
      }
    );
  },

  async fetchAllForOperation(
    idOperation: string
  ): Promise<ApiResponse<SuiviBudgetResponse>> {
    return apiRequest.get(`/operations/${idOperation}/suivi-budget`, {
      validateStatus: function (status) {
        return status === 200;
      },
    });
  },

  async downloadSuiviBudget(idOperation: string): Promise<ApiResponse<string>> {
    const response = await apiRequest.get<string>(
      `/operations/${idOperation}/suivi-budget/export`,
      {
        responseType: "text",
        validateStatus: (status) => status === 200,
      }
    );

    return {
      data: response.data,
      headers: response.headers as Record<string, string>,
      status: response.status,
    };
  },

  async fetchSuiviBudgetImportDocumentContent(
    idOperation: string,
    idSuiviBudgetImport: string
  ): Promise<ApiResponse<string[][]>> {
    return apiRequest.get(
      `/operations/${idOperation}/suivi-budget/budget-promoteur/${idSuiviBudgetImport}/content`
    );
  },

  async createSuiviBudgetConfiguration(
    budgetConfiguration: SuiviBudgetConfigurationCreationRequest
  ): Promise<ApiResponse<SuiviBudgetConfiguration[]>> {
    return apiRequest.post(
      `/operations/${budgetConfiguration.idOperation}/suivi-budget/configuration`,
      budgetConfiguration
    );
  },

  async createSuiviBudgetModele(
    idOperation: string,
    budgetModele: SuiviBudgetModeleCreationRequest
  ): Promise<ApiResponse<SuiviBudgetModele>> {
    return apiRequest.post(
      `/operations/${idOperation}/suivi-budget/modeles`,
      budgetModele
    );
  },

  async updateSuiviBudgetModele(
    idOperation: string,
    idSuiviBudgetModele: string,
    budgetModele: SuiviBudgetModeleCreationRequest
  ): Promise<ApiResponse<SuiviBudgetModele>> {
    return apiRequest.patch(
      `/operations/${idOperation}/suivi-budget/modeles/${idSuiviBudgetModele}`,
      budgetModele
    );
  },

  async associateSuiviBudgetModeleToOperation(
    idOperation: string,
    idSuiviBudgetModele: string
  ): Promise<ApiResponse<void>> {
    return apiRequest.post(
      `/operations/${idOperation}/suivi-budget/modeles/${idSuiviBudgetModele}`
    );
  },

  async fetchSuiviBudgetModeleListWithCompletion(
    idOperation: string,
    idSuiviBudgetImport: string
  ): Promise<ApiResponse<SuiviBudgetModeleWithCompletion[]>> {
    return apiRequest.get(
      `/operations/${idOperation}/suivi-budget/modeles/with-import-completion/${idSuiviBudgetImport}`
    );
  },

  downloadSuiviBudgetDocumentsPromoteur(
    idOperation: string
  ): Promise<ApiResponse<string>> {
    return apiRequest.post(
      `/operations/${idOperation}/suivi-budget/documents-promoteur`,
      {},
      {
        validateStatus: function (status) {
          return status === 200 || status === 409;
        },
      }
    );
  },
};
