import { OperationMessageHeavy } from "@/domain/operationMessageHeavy";
import { OperationMessageCanal } from "@domain/enum/operationMessageCanal";
import { TaggableUser } from "@domain/dto/taggableUser";
import { Role } from "@domain/enum/role";
import {
  formatDate,
  formatDateFileName,
  formatTime,
  is2DaysAgo,
  isToday,
  isYesterday,
  MILLISECOND_IN_MINUTE,
  sortListByCreatedAt,
} from "@/utils/dateUtils";
import groupBy from "lodash/groupBy";
import { OperationDayMessage } from "@/domain/operationDayMessage";
import sortBy from "lodash/sortBy";
import { OperationChatFilter } from "@/domain/operationChatFilter";
import { User } from "@domain/dto/user";
import { Demande } from "@/domain/demande";
import { flattenGroupIdList, getGroupRole, hasRole } from "@/utils/groupUtils";
import { Group } from "@domain/dto/group";
import { OperationRoom } from "@domain/dto/operationRoom";
import { OperationMessageEventType } from "@domain/enum/operationMessageEventType";
import uniqBy from "lodash/uniqBy";
import { isUserBanque, isUserPromoteur } from "@/utils/userUtils";
import { SortingOrder } from "@domain/enum/sortingOrder";
import { MessageMention } from "@domain/dto/messageMention";
import {
  formatDemandeName,
  formatFirstNameLastName,
} from "@/utils/formatNameUtils";
import Quill, { Delta, Op } from "quill/core";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import sanitize from "sanitize-html";
import useKeycloakStore from "@/store/keycloakModule.pinia";
import { TaggableDemande } from "@domain/dto/taggableDemande";
import { TaggableDocument } from "@domain/dto/taggableDocument";
import { useOperationChatStore } from "@/store/operationChatModule.pinia";
import { useUserProfileStore } from "@/store/userProfileModule.pinia";
import { useOperationStore } from "@/store/operationModule.pinia";
import { usePoolStore } from "@/store/poolModule.pinia";
import { OperationAccessAsRole } from "@/domain/enum/operationAccessAsRole";
import { isDeltaInsertAMention } from "@domain/utils/quillUtils";

export const ALL_PARTICIPANT_PARTNER_ID = "all-pool-participants";

const MAXIMUM_CHAT_MESSAGE_SIZE_BYTE = 5_000_000;

export function filterOperationChatMessages(
  allMessages: OperationMessageHeavy[],
  filter: OperationChatFilter,
  user: User
): OperationMessageHeavy[] {
  let operationMessages = allMessages.filter(
    (message) => message.canal === filter.canal
  );
  if (
    filter.canal === OperationMessageCanal.GENERAL &&
    hasRole(user.roles, Role.PROMOTEUR)
  ) {
    operationMessages = operationMessages.filter(
      (operationMessage) => operationMessage.idBanque === filter.idBanque
    );
  }
  if (filter.canal === OperationMessageCanal.POOL) {
    operationMessages = operationMessages.filter(
      (operationMessage) => operationMessage.idPoolGroup === filter.idPoolGroup
    );
  }
  return sortOperationChatMessages(operationMessages);
}

export function filterAllPoolParticipantMessage(
  allMessages: OperationMessageHeavy[],
  user: User
): OperationMessageHeavy[] {
  const allPoolParticipantMessages = allMessages.filter((message) => {
    return (
      user.groups.map((group) => group.id).includes(message.idGroup) &&
      message.eventType === OperationMessageEventType.ALL_POOL_PARTICIPANT &&
      message.canal === OperationMessageCanal.POOL
    );
  });

  const uniqueAllPoolParticipantMessages = uniqBy(
    allPoolParticipantMessages,
    (message) => JSON.stringify(message.text) + formatDate(message.createdAt)
  );

  return sortOperationChatMessages(uniqueAllPoolParticipantMessages);
}

export function sortOperationChatMessages(
  messages: OperationMessageHeavy[]
): OperationMessageHeavy[] {
  return sortListByCreatedAt(messages, SortingOrder.ASCENDING);
}

export function checkMessageValid(message: string): boolean {
  return message.trim().length !== 0;
}

function getSelectedCanalRoom(
  operationRoomList: OperationRoom[],
  filter: OperationChatFilter,
  me: User
): OperationRoom | undefined {
  const canalRoomList = operationRoomList.filter((operationRoom) => {
    return operationRoom.canalType === filter.canal;
  });

  return canalRoomList.find((room) => {
    const flattenedRoomUsersGroupIds = flattenGroupIdList(
      room.users.map((u) => u.groupList).flat()
    );

    if (isUserPromoteur(me) && filter.idBanque) {
      return (
        filter.idBanque === room.idGroup ||
        flattenedRoomUsersGroupIds.includes(filter.idBanque)
      );
    }
    if (isUserBanque(me) && filter.idPromoteur) {
      return (
        filter.idPromoteur === room.idGroup ||
        flattenedRoomUsersGroupIds.includes(filter.idPromoteur)
      );
    }
    return true;
  });
}

export function filterOperationDocumentList(
  operationRoomList: OperationRoom[],
  filter: OperationChatFilter,
  me: User
): TaggableDocument[] {
  const canalRoomList = operationRoomList.filter((operationRoom) => {
    return operationRoom.canalType === filter.canal;
  });

  const isCanalInterneOrGeneral = [
    OperationMessageCanal.INTERNE,
    OperationMessageCanal.GENERAL,
  ].includes(filter.canal);

  if (isCanalInterneOrGeneral) {
    const selectedCanal = getSelectedCanalRoom(operationRoomList, filter, me);
    return selectedCanal?.documents ?? [];
  }

  if (filter.canal === OperationMessageCanal.POOL) {
    const selectedCanal = canalRoomList.find(
      (room) => room.idGroup === filter.idPoolGroup
    );

    return selectedCanal?.documents ?? [];
  }

  return [];
}

export function filterOperationDemandeList(
  operationRoomList: OperationRoom[],
  filter: OperationChatFilter,
  me: User
): TaggableDemande[] {
  const canalRoomList = operationRoomList.filter((operationRoom) => {
    return operationRoom.canalType === filter.canal;
  });

  const isCanalInterneOrGeneral = [
    OperationMessageCanal.INTERNE,
    OperationMessageCanal.GENERAL,
  ].includes(filter.canal);

  if (isCanalInterneOrGeneral) {
    const selectedCanal = getSelectedCanalRoom(operationRoomList, filter, me);
    return selectedCanal?.demandes ?? [];
  }

  if (filter.canal === OperationMessageCanal.POOL) {
    const selectedCanal = canalRoomList.find(
      (room) => room.idGroup === filter.idPoolGroup
    );

    return selectedCanal?.demandes ?? [];
  }

  return [];
}

export function filterOperationChatMembers(
  operationRoomList: OperationRoom[],
  filter: OperationChatFilter,
  me: User,
  keepCurrentUser?: boolean
): TaggableUser[] {
  const filterMyself = (users: TaggableUser[]) =>
    users.filter((user) => (keepCurrentUser ? true : user.id !== me.id));

  const isCanalInterneOrGeneral = [
    OperationMessageCanal.INTERNE,
    OperationMessageCanal.GENERAL,
  ].includes(filter.canal);

  if (isCanalInterneOrGeneral) {
    const selectedCanal = getSelectedCanalRoom(operationRoomList, filter, me);
    return selectedCanal ? filterMyself(selectedCanal.users) : [];
  }

  if (filter.canal === OperationMessageCanal.POOL) {
    const foundCanal = operationRoomList.find(
      (canalMemberList) => canalMemberList.idGroup === filter.idPoolGroup
    );
    return foundCanal ? filterMyself(foundCanal.users) : [];
  }

  return [];
}

export function getParentIdFromChatMemberList(
  chatMemberList: TaggableUser[],
  concernedBanqueId: string | undefined
): string[] {
  const chatMemberOfConcernedBanque = chatMemberList.find((member) =>
    member.groupList.some((group) => group.id === concernedBanqueId)
  );
  if (!chatMemberOfConcernedBanque) {
    return [];
  }
  return chatMemberOfConcernedBanque.groupList
    .map((group) => group.parent)
    .filter((group): group is Group => !!group)
    .map((group) => group.id);
}

export function computeMessageTimeLabel(date: Date): string {
  const now = new Date(Date.now());
  const millisecondDifference = now.valueOf() - date.valueOf();

  if (millisecondDifference < MILLISECOND_IN_MINUTE) {
    return "à l'instant";
  } else {
    return formatTime(date);
  }
}

export function groupOperationMessageByDate(
  operationMessageHeavyList: OperationMessageHeavy[]
): Map<string, OperationMessageHeavy[]> {
  const group = groupBy(operationMessageHeavyList, (operationMessageHeavy) =>
    formatDateFileName(operationMessageHeavy.createdAt)
  );

  const map = new Map();
  for (const [key, value] of Object.entries(group)) {
    map.set(key, value);
  }

  return map;
}

export function computeDayMessageList(
  messageList: OperationMessageHeavy[]
): OperationDayMessage[] {
  const messageListByDate = groupOperationMessageByDate(messageList);
  const dayMessageList: OperationDayMessage[] = Array.from(
    messageListByDate,
    (item) => {
      return { day: item[0], messages: item[1] };
    }
  );

  return sortBy(dayMessageList, (elem) => elem.day);
}

export function computeDayMessageDateLabel(
  dayMessage: OperationDayMessage
): string {
  const date = dayMessage.day;
  if (isToday(date)) {
    return "Aujourd'hui";
  } else if (isYesterday(date)) {
    return "Hier";
  } else if (is2DaysAgo(date)) {
    return "Avant-hier";
  } else {
    return formatDate(new Date(date));
  }
}

export function shouldSeeOperationChat(userRoles: string[]): boolean {
  if (hasRole(userRoles, Role.TIERS)) {
    return false;
  }

  return true;
}

export function calculateTabName(
  demande: Demande,
  operationGroupList: Group[],
  userRole: Role
): string {
  if (userRole === Role.PROMOTEUR) {
    const banqueName = operationGroupList.find(
      (banque) => banque.id === demande.idBanque
    )?.name;
    return banqueName ?? "général";
  } else if (userRole === Role.BANQUE) {
    const promoteurName = operationGroupList.find((group) => {
      return getGroupRole(group.roles) === Role.PROMOTEUR;
    })?.name;
    return promoteurName ?? "général";
  } else return "";
}

export function buildOperationMessageInStorage(
  idOperation: string,
  chatType: OperationMessageCanal,
  idBanque?: string
): string {
  return `chat-${idOperation}-${chatType}-message${
    idBanque ? `-${idBanque}` : ""
  }`;
}

export function persistOperationMessageToLocalStorage(
  message: Delta,
  context: {
    idOperation: string;
    canal: OperationMessageCanal;
    idPartner: string;
  }
): void {
  localStorage.setItem(
    buildOperationMessageInStorage(
      context.idOperation,
      context.canal,
      context.idPartner
    ),
    JSON.stringify(message.ops)
  );
}

export function removeOperationMessageFromLocalStorage(context: {
  idOperation: string;
  canal: OperationMessageCanal;
  idPartner: string;
}): void {
  const key = buildOperationMessageInStorage(
    context.idOperation,
    context.canal,
    context.idPartner
  );
  localStorage.removeItem(key);
}

export function fetchOperationMessageFromLocalStorage(context: {
  idOperation: string;
  canal: OperationMessageCanal;
  idPartner: string;
}): string | null {
  const key = buildOperationMessageInStorage(
    context.idOperation,
    context.canal,
    context.idPartner
  );
  return localStorage.getItem(key);
}

export function canSendMessage(quill: Quill): boolean {
  const content = quill.getContents();
  return (
    checkMessageValid(quill.getText()) ||
    doesMessageContainSomeMentions(content) ||
    doesMessageContainSomeImages(content)
  );
}

export function canSerializeMessage(quill: Quill): boolean {
  const delta = quill.getContents();
  const serialized = JSON.stringify(delta);

  return serialized.length <= MAXIMUM_CHAT_MESSAGE_SIZE_BYTE;
}

function doesMessageContainSomeMentions(delta: Delta): boolean {
  return delta
    .map((op: Op) => {
      return isDeltaInsertAMention(op.insert);
    })
    .some((isMention) => isMention);
}

export function doesMessageContainSomeImages(delta: Delta): boolean {
  return delta.map((op: Op) => isDeltaImageOp(op)).some((isImage) => isImage);
}

export function getLastCreatedAtByPoolGroup(
  operationMessageHeavyList: OperationMessageHeavy[]
): Map<string, OperationMessageHeavy> {
  return operationMessageHeavyList.reduce(
    (
      operationMessageHeavyMap: Map<string, OperationMessageHeavy>,
      operationMessageHeavy: OperationMessageHeavy
    ) => {
      if (!operationMessageHeavy.idPoolGroup) {
        return operationMessageHeavyMap;
      }

      const currentOperationMessageHeavy = operationMessageHeavyMap.get(
        operationMessageHeavy.idPoolGroup
      );

      if (
        !currentOperationMessageHeavy ||
        operationMessageHeavy.createdAt > currentOperationMessageHeavy.createdAt
      ) {
        operationMessageHeavyMap.set(
          operationMessageHeavy?.idPoolGroup as string,
          operationMessageHeavy
        );
      }

      return operationMessageHeavyMap;
    },
    new Map<string, OperationMessageHeavy>()
  );
}

export function isDeltaImageOp(op: Op): op is { insert: { image: string } } {
  return (
    !!op.insert &&
    typeof op.insert === "object" &&
    "image" in op.insert &&
    typeof op.insert.image === "string"
  );
}

/** tranforme les deltas image Quill en custom deltas afin de pouvoir les cibler lors du rendu html */
function transformImagesToCustomImages(ops: Op[]): Op[] {
  return ops.map((op) => {
    if (isDeltaImageOp(op)) {
      return {
        insert: {
          customImage: op.insert.image,
        },
      };
    }

    return op;
  });
}

export function buildContentMessage(
  message: OperationMessageHeavy,
  chatMembers: TaggableUser[],
  chatDemandes: TaggableDemande[],
  chatDocuments: TaggableDocument[]
): string {
  const content = new Delta(message.text as Op[]);
  const keyCloakStore = useKeycloakStore();
  const currentLoggedInUser = keyCloakStore.getProfile;

  const deltaOps = transformImagesToCustomImages(content.ops);

  const converter = new QuillDeltaToHtmlConverter(deltaOps, {
    allowBackgroundClasses: false,
    inlineStyles: false,
    multiLineBlockquote: false,
    multiLineHeader: false,
    multiLineCodeblock: false,
  });

  converter.renderCustomWith((customOp) => {
    if (customOp.insert.type === "mention") {
      let val: MessageMention = customOp.insert.value;
      const member = chatMembers.find((member) => member.id === val.id);
      if (member) {
        const name = sanitize(formatFirstNameLastName(member), {
          allowedTags: [],
          allowedAttributes: {},
        });
        return `<span id="${val.id}" class="semi-bold">${val.denotationChar}${name}</span>`;
      }

      const demande = chatDemandes.find((demande) => demande.id === val.id);

      if (demande) {
        const name = sanitize(formatDemandeName(demande), {
          allowedTags: [],
          allowedAttributes: {},
        });

        const chatStore = useOperationChatStore();
        const currentChatFilters = chatStore.getOperationChatFilter;

        if (currentChatFilters.canal === OperationMessageCanal.POOL) {
          const canalIdPoolGroup = currentChatFilters.idPoolGroup;
          const idGroupList = useUserProfileStore().getUserProfile.groups.map(
            (group) => group.id
          );
          const hasUserAccessToCanalAsPool = idGroupList.some(
            (idGroup) => canalIdPoolGroup === idGroup
          );

          if (hasUserAccessToCanalAsPool) {
            const redirectUrl = `/operations/${demande.idOperation}/pool?chatOpen=true`;
            return `<a href="${redirectUrl}" class="semi-bold" id="${val.id}">${val.denotationChar}${name}</a>`;
          }
        }

        const redirectUrl = `/operations/${demande.idOperation}/demandes/${demande.id}?chatOpen=true`;
        return `<a href="${redirectUrl}" class="semi-bold" id="${val.id}">${val.denotationChar}${name}</a>`;
      }

      const document = chatDocuments.find((document) => document.id === val.id);
      if (document) {
        const name = sanitize(document.name, {
          allowedTags: [],
          allowedAttributes: {},
        });
        const redirectUrl = `/operations/${document.idOperation}/documents?chatOpen=true&documentRedirect=${document.id}`;
        return `<a href="${redirectUrl}" class="semi-bold underline" id="${val.id}">${name}</a>`;
      }

      if (currentLoggedInUser?.id === val.id) {
        const currentMember = {
          firstName: currentLoggedInUser?.firstName ?? "",
          lastName: currentLoggedInUser?.lastName ?? "",
        };
        const name = sanitize(formatFirstNameLastName(currentMember), {
          allowedTags: [],
          allowedAttributes: {},
        });
        return `<span id="${val.id}" class="semi-bold">${val.denotationChar}${name}</span>`;
      }

      const name = sanitize(val.value, {
        allowedTags: [],
        allowedAttributes: {},
      });

      return `<span id="${val.id}" class="semi-bold">${val.denotationChar}${name}</span>`;
    }

    if (customOp.insert.type === "customImage") {
      const imageBase64: string = customOp.insert.value;
      return `<img src="${imageBase64}" class="chat-image" />`;
    }

    return "";
  });

  return converter.convert();
}

export function computeChatMemberCount(
  chatMemberListCount: number,
  referentCount: number,
  isCanalInterne: boolean,
  chatMemberInterneCount: number
) {
  if (isCanalInterne) {
    return chatMemberInterneCount;
  }

  return chatMemberListCount + referentCount;
}

export function computeChatMemberListWithoutReferent(
  chatMemberList: TaggableUser[],
  referentList: User[]
) {
  const referentIdList = referentList.map((referent) => referent.id);

  return chatMemberList.filter(
    (chatMember) => !referentIdList.includes(chatMember.id)
  );
}

function computeChatMemberNameInCanalPool(
  user: TaggableUser,
  canalIdPoolGroup?: string
): string {
  const foundPoolGroup = user.groupList.find(
    (group) => group.id === canalIdPoolGroup
  );

  if (foundPoolGroup) {
    return `${user.firstName} ${user.lastName} - ${foundPoolGroup.name}`;
  }

  const foundBanqueGroup = user.groupList.find((group) =>
    group.roles.includes(Role.BANQUE)
  );

  if (foundBanqueGroup) {
    return `${user.firstName} ${user.lastName} - ${foundBanqueGroup.name}`;
  }

  return `${user.firstName} ${user.lastName}`;
}

export function formatChatMemberName(user: TaggableUser): string {
  const chatStore = useOperationChatStore();
  const filter = chatStore.getOperationChatFilter;

  if (filter.canal === OperationMessageCanal.POOL) {
    const canalIdPoolGroup = filter.idPoolGroup;
    return computeChatMemberNameInCanalPool(user, canalIdPoolGroup);
  }

  const canalIdPromoteurGroup = filter.idPromoteur;
  const canalIdBanqueGroup = filter.idBanque;

  const foundGroup = user.groupList.find(
    (group) =>
      group.id === canalIdPromoteurGroup || group.id === canalIdBanqueGroup
  );

  if (foundGroup) {
    return `${user.firstName} ${user.lastName} - ${foundGroup.name}`;
  }

  // pour membre d'un groupe parent, canalIdGroup != id du groupe
  const promoteurGroup = user.groupList.find((group) =>
    group.roles.includes(Role.PROMOTEUR)
  );

  if (promoteurGroup) {
    return `${user.firstName} ${user.lastName} - ${promoteurGroup.name}`;
  }

  const banqueGroup = user.groupList.find((group) =>
    group.roles.includes(Role.BANQUE)
  );

  if (banqueGroup) {
    return `${user.firstName} ${user.lastName} - ${banqueGroup.name}`;
  }

  return `${user.firstName} ${user.lastName}`;
}

function findPromoteurGroupNameInCurrentOperation(): string | undefined {
  return useOperationStore().getOperation.groups.find((group) => {
    return getGroupRole(group.roles) === Role.PROMOTEUR;
  })?.name;
}

function findBanqueGroupNameInCurrentOperation(
  idBanque: string
): string | undefined {
  return useOperationStore().getOperation.groups.find(
    (group) => group.id === idBanque
  )?.name;
}

function findPoolGroupNameInCurrentOperation(
  idPoolGroup: string
): string | undefined {
  return usePoolStore().getPoolOperation.participantList.find(
    (participant) => participant.participant.id === idPoolGroup
  )?.participant.name;
}

function findChefDeFileNameInCurrentOperation(): string | undefined {
  // on filtre les banques de l'opération pour les groupes banque + pool
  const currentUserGroupIdList =
    useUserProfileStore().getUserProfile.groups.map((group) => group.id);
  const allOtherBanqueVisibleByPool =
    useOperationStore().getOperation.groups.filter((group) => {
      return (
        getGroupRole(group.roles) === Role.BANQUE &&
        !currentUserGroupIdList.includes(group.id)
      );
    });

  const banqueChildGroup = allOtherBanqueVisibleByPool.find(
    (group) => !!group.parent
  );
  return banqueChildGroup?.name;
}

export const DEFAULT_MENTION_ALL_DISPLAY_NAME = "Tous";

export function formatMentionAllPartnerName(
  idPartner: string,
  canal: OperationMessageCanal,
  operationAccess: OperationAccessAsRole
) {
  if (idPartner === ALL_PARTICIPANT_PARTNER_ID) {
    return DEFAULT_MENTION_ALL_DISPLAY_NAME;
  }

  if (canal === OperationMessageCanal.INTERNE) {
    return "Interne";
  }

  if (operationAccess === OperationAccessAsRole.PROMOTEUR) {
    const banqueGroupName = findBanqueGroupNameInCurrentOperation(idPartner);
    return banqueGroupName ?? DEFAULT_MENTION_ALL_DISPLAY_NAME;
  }

  if (operationAccess === OperationAccessAsRole.BANQUE) {
    if (canal === OperationMessageCanal.GENERAL) {
      const promoteurGroupName = findPromoteurGroupNameInCurrentOperation();
      return promoteurGroupName ?? DEFAULT_MENTION_ALL_DISPLAY_NAME;
    }

    if (canal === OperationMessageCanal.POOL) {
      const poolGroupName = findPoolGroupNameInCurrentOperation(idPartner);
      return poolGroupName ?? DEFAULT_MENTION_ALL_DISPLAY_NAME;
    }
  }

  if (operationAccess === OperationAccessAsRole.PARTICIPANT_AND_BANQUE) {
    if (canal === OperationMessageCanal.GENERAL) {
      const promoteurGroupName = findPromoteurGroupNameInCurrentOperation();
      return promoteurGroupName ?? DEFAULT_MENTION_ALL_DISPLAY_NAME;
    }

    if (canal === OperationMessageCanal.POOL) {
      const banqueGroupName = findChefDeFileNameInCurrentOperation();
      return banqueGroupName ?? DEFAULT_MENTION_ALL_DISPLAY_NAME;
    }
  }

  if (operationAccess === OperationAccessAsRole.PARTICIPANT) {
    const banqueGroupName = findChefDeFileNameInCurrentOperation();
    return banqueGroupName ?? DEFAULT_MENTION_ALL_DISPLAY_NAME;
  }

  return DEFAULT_MENTION_ALL_DISPLAY_NAME;
}
